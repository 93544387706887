import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import React, { useEffect, Fragment } from 'react'
import Home from './Components/Pages/Home/Home'
import Layout from './Components/Pages/Layout/Layout'
import Subcategorias from './Components/Pages/Subcategorias/Subcategorias'
import Productos from './Components/Pages/Productos/Productos'
import ProductosEspeciales from './Components/Pages/ProductosEspeciales/ProductosEspeciales'
import Producto from './Components/Pages/Producto/Producto'


import './App.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={ <Layout/> } className='App'>
          <Route index element={ <Home/> } />
          <Route path='subcategoria/:id/' element={
            <Fragment>
              <ScrollToTop/>
              <Subcategorias/> 
            </Fragment>
          }/>
          <Route path='productos/:id/' element={
            <Fragment>
              <ScrollToTop/>
              <Productos/> 
            </Fragment>
          }/>
          <Route path='producto/:id/' element={
            <Fragment>
              <ScrollToTop/>
              <Producto/> 
            </Fragment>
          }/>
          <Route path='categoria/:id/' element={
            <Fragment>
              <ScrollToTop/>
              <ProductosEspeciales/> 
            </Fragment>
          }/>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
